<template>
  <div ref="dd" :class="{ open: toggle }" class="cselect lang">
    <button class="cselect_top" @click.stop="toggle = !toggle">
      <span class="item">
        <span class="flag"></span>
        <img :src="`/img/locales/${locale}.svg`" alt="" class="mr8" height="20" width="20">
        <span class="fw800">{{ currLocale.toUpperCase() }}</span>
      </span>
      <i class="i-arrow"></i>
    </button>
    <div class="cselect_dropdown">
      <ul class="list cselect__list">
        <li
            v-for="locale in availableLocales"
            :key="locale.code"
            @click="toggle = !toggle"
        >
          <button
              :class="[
                      `btn lang--${currLocale} ${
                        locale.code === currLocale ? 'active' : null
                      }`
                    ]"
              @click="switchLocale(locale.code)"
          >
            <img :src="`/img/locales/${locale.code}.svg`" alt="" class="mr8" height="20" width="20">
            <!--              :to="switchLocalePath(locale.code)"-->
            <!--              :to="`/${locale.code}${routeSuffix}`"-->
            <span>{{ locale.language }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, ref} from 'vue'
import {useI18n} from 'vue-i18n'
import {useClick} from '~/composables/click'
import type {LangCode} from '~/types/LangCode'

const toggle = ref(false)

const {locale, setLocale, locales} = useI18n()

const availableLocales = computed(() => locales.value)

const currLocale = computed(() => {
  return locale.value
})

const switchLocale = (localeCode: LangCode) => {
  setLocale(localeCode)
}

const dd = ref<HTMLElement>()

useClick({el: dd, toggle})
</script>

<style lang="scss" scoped>
.lang {
  cursor: pointer;
  width: unset;
}

.lang .nuxt-link-exact-active,
.active {
  color: var(--accent-secondary);
}

.cselect_dropdown {
  max-height: 41rem;
  overflow-y: auto;
  padding-right: 0;
  min-width: 135px;
}

@media screen and (max-width: 979px) {
  .cselect {
    //width: 100%;
    //max-width: 100%;
    margin-right: 4rem;
  }
  .lang {
    margin-right: 1rem;
  }
}
</style>
