<template>
  <nav class="nav">
    <ul class="list">
      <li>
        <NuxtLink :activeClass="'active'" :to="localePath('/')">
          <i class="i-analitycs"></i>
          {{ t('main') }}
        </NuxtLink>
      </li>
      <li>
        <NuxtLink :activeClass="'active'" :to="{path: localePath('/comparison'), query: {}}">
          <i class="i-comparison"></i>
          {{ t('price_comparison') }}
        </NuxtLink>
      </li>
      <li>
        <NuxtLink :activeClass="'active'" :to="localePath('/discord')">
          <i class="i-discord"></i>
          {{ t('discord_bot') }}
        </NuxtLink>
      </li>
      <li>
        <NuxtLink :activeClass="'active'" :to="localePath('/blog')">
          <svg height="20" width="20">
            <use xlink:href="/icons/sprite.svg#i-blog"></use>
          </svg>
          {{ t('blog') }}
        </NuxtLink>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import {useI18n} from 'vue-i18n'

const localePath = useLocalePath()

const {t} = useI18n()
</script>

<style lang="scss" scoped>


nav {
  flex-grow: 1;
}

ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  a {
    padding: 1rem 1.6rem;
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--surfaces-on-surface-variable);
    display: flex;
    align-items: center;
    justify-content: center;

    i, svg {
      font-size: 1.8rem;
      margin-right: 0.8rem;
    }

    &:hover {
      color: var(--surfaces-on-surface);
    }

    &.nuxt-link-exact-active {
      color: var(--accent-on-accent-secondary);
    }
  }

  .active {
    color: var(--accent-on-accent-secondary);
  }
}
</style>
